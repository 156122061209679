import React, { useEffect, useRef, useState } from 'react';
// import { Link } from 'gatsby';
import '@fontsource/poppins';

import swapChat from '../../static/swapChat.svg';
import rectangle from '../../static/rectangle.svg';
import chromeIcon from '../../static/chromeIcon.svg';
import chrome from '../../static/chrome.svg';
import mateMask from '../../static/mateMask.svg';
import tarde from '../../static/tarde.svg';
import platform from '../../static/platform.svg';
import directly from '../../static/directly.svg';
import trading from '../../static/trading.svg';
import twitterChat from '../../static/twitterChat.svg';
import openSeaChat from '../../static/openSeaChat.svg';
import discordChat from '../../static/discordChat.svg';
import footerLogo from '../../static/footerLogo.svg';
import socials from '../../static/socials.svg';
import footerBg from '../../static/footerBg.svg';
import macbook from '../../static/macbook.svg';
import reasonBg from '../../static/reasonBg.svg';
import logoIcon from '../../static/logoIcon.png';
import twitterChatImg from '../../static/twitterChatImg.png';
import twitterLink from '../../static/twitterLink.svg';
import discordLink from '../../static/discordLink.svg';
import Vector from '../../static/Vector.svg';
import githubLink from '../../static/githubLink.svg';
import discordChatImg from '../../static/discordChatImg.png';
import openSeaChatImg from '../../static/openSeaChatImg.png';

const GuideMap = [
  {
    icon: chrome,
    title: 'Add to Chrome',
    desc: 'Add SwapChat extension to your Chrome browser.We will support Firefox and Edge soon.',
  },
  {
    icon: mateMask,
    title: 'Connect Your Wallet',
    desc: 'Connect to your  Metamask wallet to SwapChat and verify your identity before you can chat and start NFT swap with others.',
  },
  {
    icon: tarde,
    title: 'Start to trade',
    desc: 'We currently only support Twitter and Opensea. More platforms are coming soon.',
  },
];

const IntroduceMap = [
  {
    icon: platform,
    title: 'Cross-platform',
    desc: 'Message friends across social platforms.',
  },
  {
    icon: directly,
    title: 'Trade Directly',
    desc: 'Message users directly from NFT marketplaces, propose trades directly.',
  },
  {
    icon: trading,
    title: 'Social Trading',
    desc: 'Join user communities from NFT marketplaces, mingle, chat, and trade with people who share your passion in a Web3 native fashion.',
  },
];

const CardsMap = [
  {
    icon: twitterChat,
    title: 'Try SwapChat on Twitter',
    desc: 'After the SwapChat Plugin is installed, you can click on anyone\'s homepage and click "create a swapchat" button to chat and trade with them.',
    img: twitterChatImg,
    imgDriection: 'right',
    type: 'primary',
    btnTitle: 'Create a SwapChat',
  },
  {
    icon: openSeaChat,
    title: 'Try SwapChat on Opensea',
    desc: [
      `After the SwapChat Plugin is installed, you can click on any NFT collection's homepage:`,
      `click"join NFT Room"to check and reply threads people post in this collection's group chat.`,
      `click"Join the conversation Thread on this NFT art piece"to social in the single art piece's group.`,
    ],
    img: openSeaChatImg,
    imgDriection: 'left',
    type: 'default',
    btnTitle: 'Join NFT Room',
  },
  {
    icon: discordChat,
    title: 'Try SwapChat on Discord',
    desc: 'After the SwapChat Plugin is installed, you can click on anyone\'s homepage and click "create a swapchat" button to chat and trade with them.',
    img: discordChatImg,
    imgDriection: 'right',
    type: 'primary',
    btnTitle: 'Create a SwapChat',
  },
];

const AddToChromeUrl =
  'https://chrome.google.com/webstore/detail/swapchat/cljogniamdljbpeapjdbdigbjmipfpgh';
const addToChrome = () => {
  window.open(AddToChromeUrl, '_black');
};

const Banner = () => {
  const [matches, setMatches] = useState<boolean>(false);
  useEffect(() => {
    const eventChangeListener = (mediaQuery: {
      matches: boolean | ((prevState: boolean) => boolean);
    }) => {
      setMatches(mediaQuery.matches);
    };
    const matchMedia = window.matchMedia('(min-width: 768px)');
    matchMedia.addEventListener('change', eventChangeListener);
    setMatches(matchMedia.matches);
  }, []);

  const generatorContainer = React.useCallback(
    (children: React.ReactFragment) => {
      let className =
        'flex items-center mb-20 md:mb-44 cursor-pointer w-60 text-white rounded-full py-2 px-8 bg-indigo-600';
      if (!matches) {
        className =
          'translate-x-0 mb-20 md:mb-44 flex mx-auto  items-center cursor-pointer w-64 text-white rounded-full py-2 px-8 bg-indigo-600';
      }

      return (
        <div onClick={addToChrome} className={className}>
          {children}
        </div>
      );
    },
    [matches]
  );

  return (
    <div
      style={{ backgroundImage: `url(${rectangle})` }}
      className="flex flex-col items-center bg-no-repeat bg-cover"
    >
      <div
        style={{
          fontSize: matches ? 96 : 40,
          lineHeight: matches ? '102px' : '60px',
        }}
        className="text-center mt-20 md:mt-44 font-bold"
      >
        <div>
          <span style={{ color: '#4642F1' }}>Social trading</span> of
        </div>
        <div> NFT across all</div>
        <div>platforms</div>
      </div>
      <div
        style={{
          margin: matches ? '40px 0' : '20px 0',
          fontSize: matches ? 20 : 16,
          lineHeight: '26px',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
        className="text-center"
      >
        <div>
          Come and experience decentralized social trading. Experience what only
          a
        </div>
        <div>Web3-native cross platform messenger unlocks.</div>
      </div>
      {generatorContainer(
        <>
          <img src={chromeIcon} />
          <span className="ml-2 text-sm">Add to Chrome now</span>
        </>
      )}
    </div>
  );
};

const Commisions = () => {
  return (
    <div
      style={{ backgroundColor: '#000', color: '#fff' }}
      className="flex flex-col items-center justify-center py-36 md:flex-row md:items-start"
    >
      <div>
        <div
          style={{ fontSize: 140, lineHeight: '62px' }}
          className="font-bold flex"
        >
          <span style={{ fontSize: 64 }}>$</span>
          <span
            style={{ paddingBottom: 10, borderBottom: '17px solid #605DEC' }}
          >
            0.00
          </span>
        </div>
        <div
          style={{ fontSize: 46, lineHeight: '40px' }}
          className="text-right"
        >
          commisions
        </div>
      </div>
      <div
        style={{ lineHeight: '26px' }}
        className="flex flex-col items-center mt-20 md:mt-0 md:ml-20 md:block md:items-start"
      >
        <div style={{ fontWeight: 700, fontSize: 24 }}>
          We don’t charge any trading fee.
        </div>
        <div style={{ fontSize: 16 }} className="mt-6">
          When you can use one tool to complete all transactions cross all
        </div>
        <div style={{ fontSize: 16 }} className="mb-6">
          platforms, why would you want to trade on platforms?
        </div>
        <div className="cursor-pointer w-52 text-center text-white rounded-full py-2 px-8 bg-indigo-600">
          Join SwapChat now
        </div>
      </div>
    </div>
  );
};

const Guide = () => {
  return (
    <div
      style={{ fontFamily: 'Poppins', fontWeight: 500 }}
      className="flex flex-col items-center"
    >
      <div style={{ fontSize: 16, lineHeight: '20px' }} className="pt-20">
        what are we doing?
      </div>
      <div className="text-3xl font-black py-6">How SwapChat works</div>
      {/* <div style={{ color: 'rgba(0,0,0,0.6)' }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi blandit
        molestie fringilla
      </div> */}
      <div style={{ color: 'rgba(0,0,0,0.6)' }} className="mb-12">
        sit in scelerisque erat sapien blandit.
      </div>
      <div className="flex justify-center mb-20 flex-col md:flex-row">
        {GuideMap.map(item => {
          return (
            <div
              className={`px-2 w-80 md:mx-20 flex flex-col items-center mt-4 md:md-0`}
              key={item.title}
            >
              <img src={item.icon} />
              <div className="mt-8 mb-4 font-bold text-lg">{item.title}</div>
              <div
                style={{ color: '#424242' }}
                className="text-sm font-light text-center"
              >
                {item.desc}
              </div>
            </div>
          );
        })}
      </div>
      <div className="mb-20 text-indigo-600 rounded-full py-2 px-6 border-gray-200 border hover:bg-gray-500 hover:text-white cursor-pointer">
        {'The complete user guide & manual'}
      </div>
    </div>
  );
};

// const Introduce = () => {
//   return (
//     <div className="bg-indigo-500 text-white flex flex-col items-center overflow-hidden">
//       <div
//         style={{ fontFamily: 'Poppins' }}
//         className="pt-20 text-3xl font-black text-center"
//       >
//         {"We don't charge any trading fee"}
//       </div>
//       <div className="text-sm font-light py-10 text-center">
//         And when you can use one tool to complete all transactions cross all
//         platforms,why would you want to trade on platforms?
//       </div>
//       <div className="flex justify-center mb-24 flex-col md:flex-row">
//         {IntroduceMap.map((item, index) => {
//           return (
//             <div
//               className="px-2 w-80  flex flex-col items-center mt-5 md:mt-0"
//               key={item.title}
//             >
//               <img
//                 src={item.icon}
//                 className={`${
//                   IntroduceMap.length - 1 === index && '-ml-7 md:ml-0'
//                 }`}
//               />
//               <div className="mt-8 mb-4 font-bold text-lg">{item.title}</div>
//               <div className="text-sm font-light px-5 text-center">
//                 {item.desc}
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

const Reason = () => {
  return (
    <div className="flex w-auto flex-col md:flex-row">
      <div
        style={{ backgroundImage: `url(${reasonBg})` }}
        className="relative bg-no-repeat bg-cover md:w-5/12"
      >
        <div className="md:absolute md:-right-40 px-10 md:px-0">
          <img src={macbook} alt="" className="pt-64 pb-40" />
        </div>
      </div>
      <div
        style={{ backgroundColor: 'rgba(196, 196, 196, 0.1)' }}
        className="text-center md:pl-64 font-bold md:w-7/12 md:text-left"
      >
        <div style={{ fontSize: 32, lineHeight: '39px' }} className="mt-20">
          Why SwapChat
        </div>
        <div
          style={{
            fontSize: 16,
            lineHeight: '24px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
          className="font-normal mt-6 mb-16"
        >
          {/* <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
          <div>
            Morbi blandit molestie fringilla sit in scelerisque erat sapien
          </div>
          <div>blandit.</div> */}
        </div>
        <div
          style={{
            borderLeft: '7px solid #165DF5',
            paddingLeft: 10,
            transform: 'translateX(-15px)',
          }}
        >
          <div style={{ fontSize: 24 }} className="mb-2">
            Cross-platform
          </div>
          <div
            style={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.6)' }}
            className="font-normal mb-8"
          >
            Message friends across social platforms.
          </div>
        </div>
        <div>
          <div style={{ fontSize: 24 }} className="mb-2">
            Trade Directly
          </div>
          <div
            style={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.6)' }}
            className="font-normal"
          >
            Message users directly from NFT marketplaces,
          </div>
          <div
            style={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.6)' }}
            className="font-normal mb-8"
          >
            propose trades directly.
          </div>
        </div>
        <div>
          <div style={{ fontSize: 24 }} className="mb-2">
            Social Trading
          </div>
          <div
            style={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.6)' }}
            className="font-normal"
          >
            Join user communities from NFT marketplaces, chat,
          </div>
          <div
            style={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.6)' }}
            className="font-normal mb-24 md:mb-64"
          >
            and trade with people.
          </div>
        </div>
      </div>
    </div>
  );
};

const Cards = () => {
  return (
    <div className="pt-16 flex flex-col items-center">
      {CardsMap.map((item, index) => {
        const { type, desc: Desc } = item;
        const btnBgStyle = type === 'primary' ? 'bg-indigo-600' : 'bg-white';
        const btnTextColor = type === 'primary' ? 'text-white' : 'text-black';
        return (
          <div
            className={`flex flex-col md:flex-row ${
              item.imgDriection === 'left' ? 'flex-col md:flex-row-reverse' : ''
            } justify-between mb-16 md:mb-36 px-10 bg-indigo-50 rounded-3xl `}
            key={item.title}
          >
            <div
              style={{ fontFamily: 'Poppins' }}
              className={`flex flex-col w-full md:w-96 py-10 text-center flex items-center ${
                item.imgDriection === 'left'
                  ? 'md:items-end md:text-right'
                  : 'md:items-start md:text-left md:pr-14'
              }`}
            >
              <img src={item.icon} alt="" />
              <div
                style={{ lineHeight: '48px' }}
                className="mt-6 font-bold  text-4xl "
              >
                {item.title}
              </div>
              <div
                style={{ lineHeight: '32px' }}
                className="mt-6 mb-10 text-gray-500"
              >
                {Array.isArray(item.desc)
                  ? item.desc.map(descItem => <p key={descItem}>{descItem}</p>)
                  : item.desc}
              </div>
              <div
                onClick={addToChrome}
                className="cursor-pointer w-48 text-center text-white rounded-full py-2 px-8 bg-indigo-600"
              >
                Add to Chrome
              </div>
            </div>
            <div
              className={`relative ${
                item.imgDriection === 'left' ? '-left-10 md: -left-0.5' : ''
              }`}
            >
              <img src={item.img} alt="" className="" />
              <div
                className={`${btnBgStyle} ${btnTextColor} flex -mt-4 justify-center cursor-pointer w-52 text-white rounded-full py-2  absolute top-3/4 md:top-80 right-0 shadow-lg`}
              >
                <img src={logoIcon} className="w-6" />
                <span className="ml-2">{item.btnTitle}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const SwapChat: React.FC = () => {
  const imgLinks = [twitterLink, discordLink, Vector, githubLink]
  const handleClick = (e: number) => {
    e === 0 ? window.open('https://twitter.com/SwapChatNFT') :
      e === 1 ? window.open('https://discord.gg/rWMavdATcM') 
        : null;
  }
  return (
    <div>
      <div style={{ background: '#FBFBFB' }}>
        <div className="flex justify-between py-4 max-w-7xl mx-auto px-2 md:px-6 md:px-18">
          <img src={swapChat} className="h-10" />
          <div
            onClick={addToChrome}
            className="z-10 cursor-pointer text-white rounded-full py-2 px-8 bg-indigo-600"
          >
            Add to Chrome
          </div>
        </div>
        <Banner />
      </div>
      <Commisions />
      <Guide />
      {/* <Introduce /> */}
      <Reason />
      <Cards />
      <div
        style={{ backgroundImage: `url(${footerBg})` }}
        className="bg-cover bg-no-repeat text-white"
      >
        <div className="flex justify-between py-32 max-w-7xl mx-auto flex-col md:flex-row px-10">
          <div className=" flex flex-col justify-center items-center md:block">
            <img src={footerLogo} alt="" />
            <div className="font-light mt-3 md:mt-8">
              ©2022 SwapChat. All Rights Reserved
            </div>
          </div>
          <div className=" flex flex-col justify-center items-center md:block mt-5 md:mt-0">
            <div className="font-light space-x-10">
              {/* <span>CTA</span>
              <span>CTA</span> */}
              <span>Contacts</span>
            </div>
            <div className="flex justify-between mt-3 md:mt-12 w-52" >
              { imgLinks.length && imgLinks.map((imgLink, index) => (
                <img className="cursor-pointer" src={imgLink} alt="" onClick={() => handleClick(index)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwapChat;
